import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import Pane from 'components/Common/Pane'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { ComponentProps, useCallback, useContext } from 'react'
import styled from 'styled-components'
import SubtitleTextBlock from 'components/Luxkit/TextBlocks/SubtitleTextBlock'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import HeroImage from 'content/components/HeroImage'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LineCommentsAltIcon from 'components/Luxkit/Icons/line/LineCommentsAltIcon'
import LineGraphBarIcon from 'components/Luxkit/Icons/line/LineGraphBarIcon'
import ModalContext from 'contexts/ModalContext'
import AgentHubExpressionOfInterestModal from 'agentHub/components/AgentHubExpressionOfInterestModal'
import LineGlobeAltIcon from 'components/Luxkit/Icons/line/LineGlobeAltIcon'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import OfferTypesCarousel from 'home/components/BrowseOfferTypes/OfferTypesCarousel'
import PartnerCarousel from 'components/Common/PartnerBrands/PartnerCarousel'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import AgentHubOrderedSellingPointsTile from './AgentHubOrderedSellingPointsTile'
import ValuePropositionItems from 'home/components/ValuePropositionBanner/ValuePropositionItems'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP } from 'agentHub/routes/AgentHubRoutes'
import useAgentHubAgentStatus from 'agentHub/hooks/useAgentHubAgentStatus'
import qs from 'qs'

const HeroImageContainer = styled(HeroImage)`
  padding: ${rem(180)} ${rem(20)} ${rem(20)} ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding: ${rem(160)} ${rem(16)};
  }
`

const TopHeroContainer = styled(LayoutContainer)`
  position: relative;
  padding: 0;
`

const HeroImageHeading = styled(Heading)`
  max-width: ${rem(600)};
`

const HeroImageSubtitlesContainer = styled(Group)`
  max-width: ${rem(600)};
`

const StyledImage = styled(ResponsiveImage)`
  max-width: ${rem(530)};
`

const AdvantagesContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.desktop} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }
`

const AdvantagesContainer2 = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.desktop} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }
`

const PartnerBrandsContainer = styled(LayoutContainer)`
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding-top: ${rem(40)};
    padding-bottom: ${rem(40)};
  }
`

const OfferTypesCarouselContainer = styled(Group)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.desktop} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }
`

const SELLING_POINTS = [
  {
    key: 'clientSavings',
    icon: <LineAwardIcon />,
    heading: 'Client Savings',
    body: 'Unlock Luxury Escapes travel deals for your clients, with commission on all bookings',
    show: true,
  },
  {
    key: 'bookingManagement',
    icon: <LineGraphBarIcon />,
    heading: 'Booking Management',
    body: 'Easily manage bookings with live status updates and self-service dashboards',
    show: true,
  },
  {
    key: 'worldwideInventory',
    icon: <LineGlobeAltIcon />,
    heading: 'Worldwide Inventory',
    body: 'Access exclusive rates from directly contracted partners all over the world',
    show: true,
  },
  {
    key: 'agentSupport',
    icon: <LineCommentsAltIcon />,
    heading: '24/7 Agent Support',
    body: 'Expert round-the-clock support for all bookings and enquiries',
    show: true,
  },
]

const ORDERED_SELLING_POINTS: Array<ComponentProps<typeof AgentHubOrderedSellingPointsTile>> = [
  {
    title: 'Register your interest now',
    description: 'Be the first to join Luxury Escapes Agent Hub.',
    itemNumber: 1,
  },
  {
    title: 'Get verified',
    description: 'Undergo a quick and secure verification process to confirm your status as a travel agent or agency.',
    itemNumber: 2,
  },
  {
    title: 'Start booking, start earning',
    description: 'Boost your business with every successful booking, thanks to Agent Hub\'s competitive commission structure.',
    itemNumber: 3,
  },
]

const HERO_IMAGE_SUBTITLES = [
  'The only way to get commissionable access to Luxury Escapes Exclusive travel offers',
  'Find and book agent-only packages and hidden deals not available to other consumers',
  'Competitive commissions on travel bookings',
  'Manage instant bookings in your own custom dashboard',
]

function AgentHubLandingPage() {
  const showModal = useContext(ModalContext)

  const toggleModal = useCallback(() => {
    showModal(<AgentHubExpressionOfInterestModal />)
  }, [showModal])

  const loggedIn = useAppSelector(selectLoggedIn)
  const userEmail = useAppSelector(state => state.auth.account.email)
  const agent = useAgentHubAgentStatus({ agentEmail: userEmail })

  const canContinueAccountCreation = loggedIn && agent.status === 'pending'
  const continueOnboardingParams = qs.stringify({
    agent_id: agent.agentId,
    agent_email: userEmail,
  })

  return <>
    <Pane>
      <HeroImageContainer
        brightness={-35}
        gravity="south"
        quality="best"
        tabletAspectRatio="2:1"
        mobileAspectRatio="2:1"
        desktopAspectRatio="16:9"
        id="3or29n7ploh4qriq852j"
        fit="center"
      >
        <TopHeroContainer>
          <Group direction="vertical" gap={20} tabletHorizontalAlign="start">
            <HeroImageHeading variant="heading1" colour="neutral-eight">Earn commission on the world's best travel deals</HeroImageHeading>

            <HeroImageSubtitlesContainer direction="vertical" gap={4}>
              {HERO_IMAGE_SUBTITLES.map((item) => (
                <SubtitleTextBlock
                  key={item}
                  variant="subtitle2"
                  colour="neutral-eight"
                  startIcon={<LineCheckIcon />}
                >
                  {item}
                </SubtitleTextBlock>
              ))}
            </HeroImageSubtitlesContainer>

            <Group direction="vertical" tabletDirection="horizontal" gap={12}>
              <TextButton
                kind="primary"
                variant="ghost"
                size="large"
                onClick={toggleModal}
              >
                Register your interest
              </TextButton>
              {canContinueAccountCreation && <TextButton
                kind="primary"
                variant="dark"
                size="large"
                to={`${LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP}?${continueOnboardingParams}`}
              >
                Continue onboarding
              </TextButton>}
            </Group>
          </Group>
        </TopHeroContainer>
      </HeroImageContainer>
    </Pane>

    <Pane>
      <AdvantagesContainer>
        <Group direction="vertical" gap={32}>
          <Group direction="vertical" gap={8}>
            <Heading variant="heading2">Agent Hub takes your business to the <i>next level</i></Heading>
            <BodyText variant="large">
              Savings for your clients, commission in your pocket
            </BodyText>
          </Group>

          <ValuePropositionItems values={SELLING_POINTS} />
        </Group>
      </AdvantagesContainer>
    </Pane>

    <Pane type="light-grey">
      <AdvantagesContainer2>
        <Group horizontalAlign="space-between" desktopDirection="horizontal" direction="vertical" desktopGap={80} gap={32}>
          <Group direction="vertical" gap={16}>
            <Group direction="vertical" gap={4}>
              <Heading variant="heading2">
                Ready, set, earn
              </Heading>
              <BodyText variant="large">
                Get verified and kickstart your commissions
              </BodyText>
            </Group>

            <Group direction="vertical" gap={16}>
              {ORDERED_SELLING_POINTS.map(item => (
                <AgentHubOrderedSellingPointsTile
                  key={item.title}
                  description={item.description}
                  title={item.title}
                  itemNumber={item.itemNumber}
                />
              ))}
            </Group>
          </Group>
          <StyledImage id="2n8iffkn0nxnmtw4914" />
        </Group>
      </AdvantagesContainer2>
    </Pane>

    <Pane>
      <OfferTypesCarouselContainer direction="vertical" gap={20}>
        <LayoutContainer>
          <Heading variant="heading2">
            The world's best travel deals all in one place
          </Heading>
        </LayoutContainer>
        <OfferTypesCarousel disableActionButton />
      </OfferTypesCarouselContainer>
    </Pane>

    <Pane>
      <PartnerBrandsContainer>
        <Group direction="vertical" gap={16}>
          <Group direction="vertical" gap={8}>
            <Heading variant="heading2">
              We partner with the world’s best brands
            </Heading>
            <BodyText variant="large">
              Access premier brands with thousands of the best hotels, tours and cruises at your fingertips
            </BodyText>
          </Group>
          <PartnerCarousel />
        </Group>
      </PartnerBrandsContainer>
    </Pane>

  </>
}

export default AgentHubLandingPage
